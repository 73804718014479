import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  Chart,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";

Chart.register([
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
]);

const SmoothLineChart = () => {
  const [borderColor1, setBorderColor1] = useState("#B692F6");
  const [borderColor2, setBorderColor2] = useState("#B692F6");
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    // Assuming 'receivedData' is the data received from the backend
    const receivedData = {
      May: [
        {
          site: 1,
          site__name: "dvldental",
          site__base_url: "https://dvldental.com",
          site__category__name: "temporary",
          site__image: "Screenshot_21.png",
          access_count: 1919,
        },
        {
          site: 5,
          site__name: "Expert review of respiratory medicine",
          site__base_url: "https://www.tandfonline.com/journals/ierx20",
          site__category__name: null,
          site__image: "Screenshot_19.png",
          access_count: 842,
        },
        {
          site: 7,
          site__name: "google",
          site__base_url: "https://www.google.com",
          site__category__name: null,
          site__image: "Screenshot_17.png",
          access_count: 158,
        },
        {
          site: 34,
          site__name: "Back End",
          site__base_url: "http://github.com",
          site__category__name: "124",
          site__image: "bw_pattern.jpg",
          access_count: 44,
        },
        {
          site: 21,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "sitelogo_cYqww19.jpeg",
          access_count: 39,
        },
        {
          site: 8,
          site__name: "semantic scholar",
          site__base_url: "https://www.semanticscholar.org/",
          site__category__name: null,
          site__image: "Screenshot_16.png",
          access_count: 36,
        },
        {
          site: 3,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "Screenshot_20.png",
          access_count: 17,
        },
        {
          site: 6,
          site__name: "Angle Orthodontist",
          site__base_url: "https://meridian.allenpress.com/angle-orthodontist",
          site__category__name: "Medical",
          site__image: "Screenshot_18.png",
          access_count: 16,
        },
        {
          site: 35,
          site__name: "auth master",
          site__base_url: "http://authmaster.netlify.app",
          site__category__name: "124",
          site__image: "dots.png",
          access_count: 12,
        },
        {
          site: 9,
          site__name: "example",
          site__base_url: "https://dvlonline.com",
          site__category__name: null,
          site__image: "Screenshot_21_elk1nKN.png",
          access_count: 8,
        },
        {
          site: 12,
          site__name: "semantic",
          site__base_url: "https://www.semanticscholar.org",
          site__category__name: null,
          site__image: "Screenshot_17_G6x6Bvy.png",
          access_count: 6,
        },
        {
          site: 27,
          site__name: "dummy 3.O",
          site__base_url: "http://github.com",
          site__category__name: null,
          site__image: "",
          access_count: 5,
        },
        {
          site: 33,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "sitelogo_1Nk8RcL.jpeg",
          access_count: 4,
        },
        {
          site: 10,
          site__name: "Temp",
          site__base_url: "https://html.themeholy.com/mediax",
          site__category__name: null,
          site__image: "Screenshot_19_6fIMJTc.png",
          access_count: 4,
        },
        {
          site: 13,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: null,
          site__image: "Screenshot_18_IJZapS8.png",
          access_count: 3,
        },
        {
          site: 20,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "bw_pattern.jpg",
          access_count: 1,
        },
        {
          site: 29,
          site__name: "Angle orthodontist-New",
          site__base_url: "https://meridian.allenpress.com/angle-orthodontist",
          site__category__name: "new temp",
          site__image: "",
          access_count: 1,
        },
        {
          site: 28,
          site__name: "Dummy",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "",
          access_count: 1,
        },
      ],
      April: [
        {
          site: 1,
          site__name: "dvldental",
          site__base_url: "https://dvldental.com",
          site__category__name: "temporary",
          site__image: "Screenshot_21.png",
          access_count: 19,
        },
        {
          site: 5,
          site__name: "Expert review of respiratory medicine",
          site__base_url: "https://www.tandfonline.com/journals/ierx20",
          site__category__name: null,
          site__image: "Screenshot_19.png",
          access_count: 842,
        },
        {
          site: 7,
          site__name: "google",
          site__base_url: "https://www.google.com",
          site__category__name: null,
          site__image: "Screenshot_17.png",
          access_count: 158,
        },
        {
          site: 34,
          site__name: "Back End",
          site__base_url: "http://github.com",
          site__category__name: "124",
          site__image: "bw_pattern.jpg",
          access_count: 144,
        },
        {
          site: 21,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "sitelogo_cYqww19.jpeg",
          access_count: 39,
        },
        {
          site: 8,
          site__name: "semantic scholar",
          site__base_url: "https://www.semanticscholar.org/",
          site__category__name: null,
          site__image: "Screenshot_16.png",
          access_count: 36,
        },
        {
          site: 3,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "Screenshot_20.png",
          access_count: 17,
        },
        {
          site: 6,
          site__name: "Angle Orthodontist",
          site__base_url: "https://meridian.allenpress.com/angle-orthodontist",
          site__category__name: "Medical",
          site__image: "Screenshot_18.png",
          access_count: 16,
        },
        {
          site: 35,
          site__name: "auth master",
          site__base_url: "http://authmaster.netlify.app",
          site__category__name: "124",
          site__image: "dots.png",
          access_count: 12,
        },
        {
          site: 9,
          site__name: "example",
          site__base_url: "https://dvlonline.com",
          site__category__name: null,
          site__image: "Screenshot_21_elk1nKN.png",
          access_count: 8,
        },
        {
          site: 12,
          site__name: "semantic",
          site__base_url: "https://www.semanticscholar.org",
          site__category__name: null,
          site__image: "Screenshot_17_G6x6Bvy.png",
          access_count: 6,
        },
        {
          site: 27,
          site__name: "dummy 3.O",
          site__base_url: "http://github.com",
          site__category__name: null,
          site__image: "",
          access_count: 5,
        },
        {
          site: 33,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "sitelogo_1Nk8RcL.jpeg",
          access_count: 4,
        },
        {
          site: 10,
          site__name: "Temp",
          site__base_url: "https://html.themeholy.com/mediax",
          site__category__name: null,
          site__image: "Screenshot_19_6fIMJTc.png",
          access_count: 4,
        },
        {
          site: 13,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: null,
          site__image: "Screenshot_18_IJZapS8.png",
          access_count: 3,
        },
        {
          site: 20,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "bw_pattern.jpg",
          access_count: 1,
        },
        {
          site: 29,
          site__name: "Angle orthodontist-New",
          site__base_url: "https://meridian.allenpress.com/angle-orthodontist",
          site__category__name: "new temp",
          site__image: "",
          access_count: 1,
        },
        {
          site: 28,
          site__name: "Dummy",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "",
          access_count: 1,
        },
      ],
      March: [
        {
          site: 1,
          site__name: "dvldental",
          site__base_url: "https://dvldental.com",
          site__category__name: "temporary",
          site__image: "Screenshot_21.png",
          access_count: 1919,
        },
        {
          site: 5,
          site__name: "Expert review of respiratory medicine",
          site__base_url: "https://www.tandfonline.com/journals/ierx20",
          site__category__name: null,
          site__image: "Screenshot_19.png",
          access_count: 842,
        },
        {
          site: 7,
          site__name: "google",
          site__base_url: "https://www.google.com",
          site__category__name: null,
          site__image: "Screenshot_17.png",
          access_count: 158,
        },
        {
          site: 34,
          site__name: "Back End",
          site__base_url: "http://github.com",
          site__category__name: "124",
          site__image: "bw_pattern.jpg",
          access_count: 44,
        },
        {
          site: 21,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "sitelogo_cYqww19.jpeg",
          access_count: 39,
        },
        {
          site: 8,
          site__name: "semantic scholar",
          site__base_url: "https://www.semanticscholar.org/",
          site__category__name: null,
          site__image: "Screenshot_16.png",
          access_count: 36,
        },
        {
          site: 3,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "Screenshot_20.png",
          access_count: 17,
        },
        {
          site: 6,
          site__name: "Angle Orthodontist",
          site__base_url: "https://meridian.allenpress.com/angle-orthodontist",
          site__category__name: "Medical",
          site__image: "Screenshot_18.png",
          access_count: 16,
        },
        {
          site: 35,
          site__name: "auth master",
          site__base_url: "http://authmaster.netlify.app",
          site__category__name: "124",
          site__image: "dots.png",
          access_count: 12,
        },
        {
          site: 9,
          site__name: "example",
          site__base_url: "https://dvlonline.com",
          site__category__name: null,
          site__image: "Screenshot_21_elk1nKN.png",
          access_count: 8,
        },
        {
          site: 12,
          site__name: "semantic",
          site__base_url: "https://www.semanticscholar.org",
          site__category__name: null,
          site__image: "Screenshot_17_G6x6Bvy.png",
          access_count: 6,
        },
        {
          site: 27,
          site__name: "dummy 3.O",
          site__base_url: "http://github.com",
          site__category__name: null,
          site__image: "",
          access_count: 5,
        },
        {
          site: 33,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "sitelogo_1Nk8RcL.jpeg",
          access_count: 4,
        },
        {
          site: 10,
          site__name: "Temp",
          site__base_url: "https://html.themeholy.com/mediax",
          site__category__name: null,
          site__image: "Screenshot_19_6fIMJTc.png",
          access_count: 4,
        },
        {
          site: 13,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: null,
          site__image: "Screenshot_18_IJZapS8.png",
          access_count: 3,
        },
        {
          site: 20,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "bw_pattern.jpg",
          access_count: 1,
        },
        {
          site: 29,
          site__name: "Angle orthodontist-New",
          site__base_url: "https://meridian.allenpress.com/angle-orthodontist",
          site__category__name: "new temp",
          site__image: "",
          access_count: 1,
        },
        {
          site: 28,
          site__name: "Dummy",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "",
          access_count: 1,
        },
      ],
      February: [
        {
          site: 1,
          site__name: "dvldental",
          site__base_url: "https://dvldental.com",
          site__category__name: "temporary",
          site__image: "Screenshot_21.png",
          access_count: 1919,
        },
        {
          site: 5,
          site__name: "Expert review of respiratory medicine",
          site__base_url: "https://www.tandfonline.com/journals/ierx20",
          site__category__name: null,
          site__image: "Screenshot_19.png",
          access_count: 842,
        },
        {
          site: 7,
          site__name: "google",
          site__base_url: "https://www.google.com",
          site__category__name: null,
          site__image: "Screenshot_17.png",
          access_count: 158,
        },
        {
          site: 34,
          site__name: "Back End",
          site__base_url: "http://github.com",
          site__category__name: "124",
          site__image: "bw_pattern.jpg",
          access_count: 44,
        },
        {
          site: 21,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "sitelogo_cYqww19.jpeg",
          access_count: 39,
        },
        {
          site: 8,
          site__name: "semantic scholar",
          site__base_url: "https://www.semanticscholar.org/",
          site__category__name: null,
          site__image: "Screenshot_16.png",
          access_count: 36,
        },
        {
          site: 3,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "Screenshot_20.png",
          access_count: 17,
        },
        {
          site: 6,
          site__name: "Angle Orthodontist",
          site__base_url: "https://meridian.allenpress.com/angle-orthodontist",
          site__category__name: "Medical",
          site__image: "Screenshot_18.png",
          access_count: 16,
        },
        {
          site: 35,
          site__name: "auth master",
          site__base_url: "http://authmaster.netlify.app",
          site__category__name: "124",
          site__image: "dots.png",
          access_count: 12,
        },
        {
          site: 9,
          site__name: "example",
          site__base_url: "https://dvlonline.com",
          site__category__name: null,
          site__image: "Screenshot_21_elk1nKN.png",
          access_count: 8,
        },
        {
          site: 12,
          site__name: "semantic",
          site__base_url: "https://www.semanticscholar.org",
          site__category__name: null,
          site__image: "Screenshot_17_G6x6Bvy.png",
          access_count: 6,
        },
        {
          site: 27,
          site__name: "dummy 3.O",
          site__base_url: "http://github.com",
          site__category__name: null,
          site__image: "",
          access_count: 5,
        },
        {
          site: 33,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "sitelogo_1Nk8RcL.jpeg",
          access_count: 4,
        },
        {
          site: 10,
          site__name: "Temp",
          site__base_url: "https://html.themeholy.com/mediax",
          site__category__name: null,
          site__image: "Screenshot_19_6fIMJTc.png",
          access_count: 4,
        },
        {
          site: 13,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: null,
          site__image: "Screenshot_18_IJZapS8.png",
          access_count: 3,
        },
        {
          site: 20,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "bw_pattern.jpg",
          access_count: 1,
        },
        {
          site: 29,
          site__name: "Angle orthodontist-New",
          site__base_url: "https://meridian.allenpress.com/angle-orthodontist",
          site__category__name: "new temp",
          site__image: "",
          access_count: 1,
        },
        {
          site: 28,
          site__name: "Dummy",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "",
          access_count: 1,
        },
      ],
      January: [
        {
          site: 1,
          site__name: "dvldental",
          site__base_url: "https://dvldental.com",
          site__category__name: "temporary",
          site__image: "Screenshot_21.png",
          access_count: 1919,
        },
        {
          site: 5,
          site__name: "Expert review of respiratory medicine",
          site__base_url: "https://www.tandfonline.com/journals/ierx20",
          site__category__name: null,
          site__image: "Screenshot_19.png",
          access_count: 842,
        },
        {
          site: 7,
          site__name: "google",
          site__base_url: "https://www.google.com",
          site__category__name: null,
          site__image: "Screenshot_17.png",
          access_count: 158,
        },
        {
          site: 34,
          site__name: "Back End",
          site__base_url: "http://github.com",
          site__category__name: "124",
          site__image: "bw_pattern.jpg",
          access_count: 44,
        },
        {
          site: 21,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "sitelogo_cYqww19.jpeg",
          access_count: 39,
        },
        {
          site: 8,
          site__name: "semantic scholar",
          site__base_url: "https://www.semanticscholar.org/",
          site__category__name: null,
          site__image: "Screenshot_16.png",
          access_count: 36,
        },
        {
          site: 3,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "Screenshot_20.png",
          access_count: 17,
        },
        {
          site: 6,
          site__name: "Angle Orthodontist",
          site__base_url: "https://meridian.allenpress.com/angle-orthodontist",
          site__category__name: "Medical",
          site__image: "Screenshot_18.png",
          access_count: 16,
        },
        {
          site: 35,
          site__name: "auth master",
          site__base_url: "http://authmaster.netlify.app",
          site__category__name: "124",
          site__image: "dots.png",
          access_count: 12,
        },
        {
          site: 9,
          site__name: "example",
          site__base_url: "https://dvlonline.com",
          site__category__name: null,
          site__image: "Screenshot_21_elk1nKN.png",
          access_count: 8,
        },
        {
          site: 12,
          site__name: "semantic",
          site__base_url: "https://www.semanticscholar.org",
          site__category__name: null,
          site__image: "Screenshot_17_G6x6Bvy.png",
          access_count: 6,
        },
        {
          site: 27,
          site__name: "dummy 3.O",
          site__base_url: "http://github.com",
          site__category__name: null,
          site__image: "",
          access_count: 5,
        },
        {
          site: 33,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "sitelogo_1Nk8RcL.jpeg",
          access_count: 4,
        },
        {
          site: 10,
          site__name: "Temp",
          site__base_url: "https://html.themeholy.com/mediax",
          site__category__name: null,
          site__image: "Screenshot_19_6fIMJTc.png",
          access_count: 4,
        },
        {
          site: 13,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: null,
          site__image: "Screenshot_18_IJZapS8.png",
          access_count: 3,
        },
        {
          site: 20,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "bw_pattern.jpg",
          access_count: 1,
        },
        {
          site: 29,
          site__name: "Angle orthodontist-New",
          site__base_url: "https://meridian.allenpress.com/angle-orthodontist",
          site__category__name: "new temp",
          site__image: "",
          access_count: 1,
        },
        {
          site: 28,
          site__name: "Dummy",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "",
          access_count: 1,
        },
      ],
      December: [
        {
          site: 1,
          site__name: "dvldental",
          site__base_url: "https://dvldental.com",
          site__category__name: "temporary",
          site__image: "Screenshot_21.png",
          access_count: 1919,
        },
        {
          site: 5,
          site__name: "Expert review of respiratory medicine",
          site__base_url: "https://www.tandfonline.com/journals/ierx20",
          site__category__name: null,
          site__image: "Screenshot_19.png",
          access_count: 842,
        },
        {
          site: 7,
          site__name: "google",
          site__base_url: "https://www.google.com",
          site__category__name: null,
          site__image: "Screenshot_17.png",
          access_count: 158,
        },
        {
          site: 34,
          site__name: "Back End",
          site__base_url: "http://github.com",
          site__category__name: "124",
          site__image: "bw_pattern.jpg",
          access_count: 44,
        },
        {
          site: 21,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "sitelogo_cYqww19.jpeg",
          access_count: 39,
        },
        {
          site: 8,
          site__name: "semantic scholar",
          site__base_url: "https://www.semanticscholar.org/",
          site__category__name: null,
          site__image: "Screenshot_16.png",
          access_count: 36,
        },
        {
          site: 3,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "Screenshot_20.png",
          access_count: 17,
        },
        {
          site: 6,
          site__name: "Angle Orthodontist",
          site__base_url: "https://meridian.allenpress.com/angle-orthodontist",
          site__category__name: "Medical",
          site__image: "Screenshot_18.png",
          access_count: 16,
        },
        {
          site: 35,
          site__name: "auth master",
          site__base_url: "http://authmaster.netlify.app",
          site__category__name: "124",
          site__image: "dots.png",
          access_count: 12,
        },
        {
          site: 9,
          site__name: "example",
          site__base_url: "https://dvlonline.com",
          site__category__name: null,
          site__image: "Screenshot_21_elk1nKN.png",
          access_count: 8,
        },
        {
          site: 12,
          site__name: "semantic",
          site__base_url: "https://www.semanticscholar.org",
          site__category__name: null,
          site__image: "Screenshot_17_G6x6Bvy.png",
          access_count: 6,
        },
        {
          site: 27,
          site__name: "dummy 3.O",
          site__base_url: "http://github.com",
          site__category__name: null,
          site__image: "",
          access_count: 5,
        },
        {
          site: 33,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "sitelogo_1Nk8RcL.jpeg",
          access_count: 4,
        },
        {
          site: 10,
          site__name: "Temp",
          site__base_url: "https://html.themeholy.com/mediax",
          site__category__name: null,
          site__image: "Screenshot_19_6fIMJTc.png",
          access_count: 4,
        },
        {
          site: 13,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: null,
          site__image: "Screenshot_18_IJZapS8.png",
          access_count: 3,
        },
        {
          site: 20,
          site__name: "example",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "bw_pattern.jpg",
          access_count: 1,
        },
        {
          site: 29,
          site__name: "Angle orthodontist-New",
          site__base_url: "https://meridian.allenpress.com/angle-orthodontist",
          site__category__name: "new temp",
          site__image: "",
          access_count: 1,
        },
        {
          site: 28,
          site__name: "Dummy",
          site__base_url: "http://example.com",
          site__category__name: "temporary",
          site__image: "",
          access_count: 1,
        },
      ],
    };

    const aggregatedData = {};

    for (const month in receivedData) {
      if (Object.hasOwnProperty.call(receivedData, month)) {
        const siteData = receivedData[month];
        let totalAccessCount = 0;

        siteData.forEach((site) => {
          totalAccessCount += site.access_count;
        });

        aggregatedData[month] = totalAccessCount;
      }
    }

    const labels = Object.keys(aggregatedData);
    const accessCounts = Object.values(aggregatedData);

    const newChartData = {
      labels: labels,
      datasets: [
        {
          label: "Access Count",
          data: accessCounts,
          fill: false,
          borderColor: borderColor1,
          tension: 0.4,
        },
      ],
    };

    setChartData(newChartData);
  }, []);

  // -----------------------WORKING---------------------------
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Chart.js Line Chart - Cubic interpolation mode",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            if (label) {
              return label + ": " + context.parsed.y;
            }
            return context.parsed.y;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          values: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        },
        grid: {
          drawBorder: false,
          drawOnChartArea: false,
          borderColor: "transparent",
        },
      },
      y: {
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          stepSize: 700,
          callback: function (value, index, values) {
            return value;
          },
        },
        grid: {
          drawBorder: true,
          drawOnChartArea: true,
          borderColor: "",
        },
      },
    },
    onHover: (event, chartElement) => {
      if (chartElement.length) {
        if (chartElement[0].datasetIndex === 0) setBorderColor1("#7F56D9");
        else setBorderColor2("#7F56D9");
      } else {
        setBorderColor1("#B692F6");
        setBorderColor2("#B692F6");
      }
    },
  };

  return (
    <div className='relative' style={{ width: "100%", height: "100%" }}>
      {chartData && <Line data={chartData} options={options} />}
    </div>
  );
};

export default SmoothLineChart;
